import { Project } from "@/interfaces/projects";
import { useProjectStore } from "@/stores/projects";
import { usePage } from "@inertiajs/vue3";
import { storeToRefs } from "pinia";

export const useProjectState = () => {
  const page = usePage();
  const projectStore = useProjectStore();
  const refs = storeToRefs(projectStore);
  const projects = page.props.projects as Project[];
  return {
    projects,
    projectStore,
    ...refs,
  };
};
