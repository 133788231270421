<template>
  <div
    class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col h-full"
  >
    <div class="flex bg-gray-100 text-xs leading-6 text-gray-700 lg:flex-auto">
      <div
        :class="[
          'w-full lg:grid lg:grid-cols-7 lg:gap-px auto-rows-fr',
          `lg:grid-rows-${rowCountInMonth(dates.length)}`,
        ]"
      >
        <div
          v-for="dayItem in dates"
          :key="dayItem.key"
          :class="[
            'relative px-1.5 py-1 h-full text-md font-bold cursor-pointer hover:bg-gray-100 bg-gray-200 text-gray-500',
            dayItem.isCurrentMonth && 'bg-white',
            // dayItem.isSaturday && '!bg-blue-100',
            // dayItem.isSunday && '!bg-red-50',
          ]"
        >
          <div class="flex gap-1 items-center">
            <time
              :class="
                dayItem.isToday &&
                'flex h-6 w-6 items-center justify-center rounded-md bg-main-600 font-semibold text-white'
              "
              >{{ dayItem.date.get("date") }}</time
            >
            <span class="text-gray-400 text-md font-normal">
              {{ dayItem.date.format("MMM") }}
            </span>
          </div>
          <div
            v-if="dayItem.events.length > 0"
            class="mt-1 flex flex-col gap-0.5"
          >
            <div
              :data-color="event.project?.color"
              v-for="event in dayItem.events"
              :key="event.name"
              @click="onEventClick(event)"
              class="truncate font-medium px-2 rounded-md flex justify-between items-center"
              :style="{
                backgroundColor: event.project?.color,
                color: event.project?.is_dark ? 'white' : 'black',
              }"
            >
              <span>{{ event.name }}</span>
              <!-- <span>{{ event.events_count }}</span> -->
            </div>
            <!-- <div v-if="dayItem.events.length > 2" class="text-gray-500">
              + {{ dayItem.events.length - 2 }} more
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateItem, StartDayOfWeeks } from "@/constants/calendar";
import { useCalendarState } from "@/hooks/use_calendars";
import {
  CalendarCellEvent,
  Event,
  MonthlyCalendarCellProjectMetadata,
} from "@/interfaces/events";
import { SlideOverType } from "@/stores/calendars";
import { rowCountInMonth } from "@/utils/calendar_util";
import dayjsUtil from "@/utils/dayjs_util";
import { Dayjs } from "dayjs";
import { computed } from "vue";

interface CalendarMonthlyProps {
  displayedDate?: Dayjs;
  dates: DateItem[];
  startDayOfWeek?: StartDayOfWeeks;
  dateOnClick: (day: Dayjs) => any;
  groupedEvent?: CalendarCellEvent;
}

const props = withDefaults(defineProps<CalendarMonthlyProps>(), {
  displayedDate: () => dayjsUtil(),
  startDayOfWeek: StartDayOfWeeks.Monday,
});

const { calendarStore } = useCalendarState();

const dates = computed(() =>
  props.dates.map((date) => {
    const formattedDate = date.date.format("YYYY-MM-DD");
    const events = props.groupedEvent?.[formattedDate] ?? [];
    return {
      ...date,
      events,
    };
  })
);
const onEventClick = (event: Event) => {
  calendarStore.setSelectedEvent(event);
  calendarStore.setSlideOverType(SlideOverType.WEEKLY);
};
</script>
