// JsFromRoutes CacheKey 300a3ec8b2472de90f4365d6b58e4c16
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  monthlyEvents: definePathHelper('get', '/api/monthly_events'),
  weeklyEvents: definePathHelper('get', '/api/weekly_events'),
  dailyEvents: definePathHelper('get', '/api/daily_events'),
}
