import { Event } from "@/interfaces/events";
import { Project } from "@/interfaces/projects";
import { defineStore } from "pinia";

export enum FormEventMode {
  CREATE = "create",
  EDIT = "edit",
}
interface GlobalStore {
  isModalFormEventShown: boolean;
  formMode: FormEventMode;
  eventToDelete?: Event;
  eventToUpdateStatus?: UpdateStatusEvent;
  eventToEdit?: Event;
  projectToDelete?: Project;
}

interface UpdateStatusEvent {
  event: Event;
  status: string;
}

export const useGlobalStore = defineStore("globalStore", {
  state: (): GlobalStore => ({
    isModalFormEventShown: false,
    formMode: FormEventMode.CREATE,
    eventToDelete: undefined,
    eventToEdit: undefined,
    eventToUpdateStatus: undefined,
    projectToDelete: undefined,
  }),
  actions: {
    setIsModalFormEventShown(val: boolean) {
      this.isModalFormEventShown = val;
    },
    setFormMode(mode: FormEventMode) {
      this.formMode = mode;
    },
    setEventToDelete(event: Event) {
      this.eventToDelete = event;
    },
    clearEventToDelete() {
      this.eventToDelete = undefined;
    },
    setEventToEdit(event: Event) {
      this.eventToEdit = event;
    },
    clearEventToEdit() {
      this.eventToEdit = undefined;
    },
    setProjectToDelete(project: Project) {
      this.projectToDelete = project;
    },
    clearProjectToDelete() {
      this.projectToDelete = undefined;
    },
    setEventToUpdateStatus(event: Event, status: string) {
      this.eventToUpdateStatus = { event, status };
    },
    clearEventToUpdateStatus() {
      this.eventToUpdateStatus = undefined;
    },
    editEvent(event: Event) {
      this.setIsModalFormEventShown(true);
      this.setFormMode(FormEventMode.EDIT);
      this.setEventToEdit(event);
    },
  },
});
