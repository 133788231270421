<template>
  <header
    class="flex items-center justify-between border-b border-gray-200 px-6 h-[60px] lg:flex-none"
  >
    <MoleculeProjectSelector
      :projects="projects"
      :on-selected-change="
        (data) => persistedCalendarStore.setSelectedProjects(data)
      "
      :on-clear-all="() => persistedCalendarStore.setSelectedProjects([])"
    />
    <h1 class="text-base font-semibold leading-6 text-gray-900">
      <time datetime="2022-01">{{ displayedDate.format("MMMM YYYY") }}</time>
      <!-- <time v-if="isSameMonth" datetime="2022-01">
        {{ monthArray[0].format("MMMM YYYY") }}</time
      >
      <time v-else datetime="2022-01"
        >{{ monthArray[0].format("MMMM") }} -
        {{ monthArray[1].format("MMMM") }}
        {{ monthArray[1].format("YYYY") }}</time
      > -->
    </h1>
    <div class="flex items-center">
      <div class="relative flex rounded-xl bg-white items-stretch">
        <div class="relative">
          <button
            type="button"
            @click="showCustomPicker = !showCustomPicker"
            class="flex items-center justify-center text-gray-400 hover:text-gray-500 w-9 px-2"
          >
            <span class="sr-only">Custom month</span>
            <CalendarDaysIcon aria-hidden="true" />
          </button>
          <OnClickOutside
            v-if="showCustomPicker"
            @trigger="showCustomPicker = !showCustomPicker"
          >
            <div
              v-if="showCustomPicker"
              class="absolute z-10 top-[40px] -left-[220px]"
            >
              <vue-date-picker
                auto-apply
                :enable-time-picker="false"
                v-model="selectedCustomDate"
                inline
                @date-update="onDateClicked"
              />
            </div>
          </OnClickOutside>
        </div>
        <button
          type="button"
          @click="prevOnClick"
          class="flex items-center justify-center text-gray-400 hover:text-gray-500 w-9 px-2"
        >
          <span class="sr-only">Previous month</span>
          <ChevronLeftIcon aria-hidden="true" />
        </button>
        <button
          type="button"
          @click="todayButtonOnClick"
          class="px-3.5 text-sm font-semibold text-gray-900 block"
        >
          Today
        </button>
        <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          type="button"
          @click="nextOnClick"
          class="flex items-center justify-center rounded-r-md text-gray-400 hover:text-gray-500 w-9 px-2"
        >
          <span class="sr-only">Next month</span>
          <ChevronRightIcon aria-hidden="true" />
        </button>
      </div>
      <nav class="flex space-x-4 ml-4" aria-label="Tabs">
        <div
          v-for="tab in calendarViews"
          @click="calendarViewOnSelect && calendarViewOnSelect(tab)"
          :key="tab"
          :class="[
            tab == calendarView
              ? 'text-main-500'
              : 'text-gray-500 hover:text-gray-700',
            'rounded-md px-1 text-sm font-semibold cursor-pointer',
          ]"
        >
          {{ tab }}
        </div>
      </nav>
      <AtomButton class="ml-4" @click="newEventOnClick">
        <PlusIcon class="-ml-0.5 h-5 w-" aria-hidden="true" />
        New Event
      </AtomButton>
    </div>
  </header>
  <!-- header for week -->
  <div
    v-if="calendarView == CalendarView.Week"
    ref="containerNav"
    class="flex-none bg-white shadow ring-1 ring-black ring-opacity-5 pr-0.5"
  >
    <div
      ref="weeklyHeaderRef"
      class="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 bg-gray-50 sm:grid"
    >
      <div class="col-end-1 w-16" />
      <div
        v-for="dateItem in dates"
        class="relative flex items-center justify-center py-3"
        :key="dateItem.key"
      >
        <span :class="dateItem.isToday ? 'flex items-baseline' : ''"
          >{{ dateItem.date.format("ddd") }}
          <span
            :class="[
              dateItem.isToday
                ? 'ml-1.5 flex h-8 w-8 text-white bg-indigo-600 font-semibold rounded-md'
                : 'text-gray-900',
              'items-center justify-center font-semibold',
            ]"
            >{{ dateItem.date.format("D") }}</span
          ></span
        >
      </div>
    </div>
  </div>
  <!-- header for month -->
  <div
    v-if="calendarView == CalendarView.Month"
    class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
  >
    <div
      v-for="dateItem in datesInWeek(displayedDate, startDayOfWeek)"
      :key="dateItem.date.format()"
      :class="[
        'py-3 bg-white',
        // !dateItem.isSunday && !dateItem.isSaturday && 'bg-gray-50',
        // dateItem.isSaturday && 'bg-blue-100',
        // dateItem.isSunday && 'bg-red-50',
      ]"
    >
      {{ dateItem.date.format("ddd") }}
    </div>
  </div>
  <!-- header for day -->
  <div
    v-if="calendarView == CalendarView.Day"
    class="grid grid-cols-1 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
  >
    <div
      :key="displayedDate.format('YYYY-MM-DD')"
      :class="[
        'py-3 bg-white',
        // !dateItem.isSunday && !dateItem.isSaturday && 'bg-gray-50',
        // dateItem.isSaturday && 'bg-blue-100',
        // dateItem.isSunday && 'bg-red-50',
      ]"
    >
      {{
        dayjsUtil().isSame(displayedDate, "day")
          ? `Today${displayedDate.format(", D MMMM")}`
          : displayedDate.format("dddd, D MMMM")
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { CalendarView, DateItem, StartDayOfWeeks } from "@/constants/calendar";
import { datesInWeek } from "@/utils/calendar_util";
import dayjsUtil from "@/utils/dayjs_util";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { Dayjs } from "dayjs";
import { computed, ref, watch, watchEffect } from "vue";
import MoleculeProjectSelector from "./MoleculeProjectSelector.vue";
import { Project } from "@/interfaces/projects";
import { usePersistedCalendarStore } from "@/stores/calendars";
import AtomButton from "../atoms/AtomButton.vue";
import { useGlobalState } from "@/hooks/global";
import { useProjectState } from "@/hooks/use_projects";
import { CalendarDaysIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { OnClickOutside } from "@vueuse/components";

interface CalendarHeaderProps {
  displayedDate: Dayjs;
  dates: DateItem[];
  startDayOfWeek?: StartDayOfWeeks;
  calendarView: CalendarView;
  prevOnClick: () => any;
  nextOnClick: () => any;
  onCustomDate: (date: Dayjs) => any;
  todayButtonOnClick: () => any;
  addEventOnClick: () => any;
  calendarViewOnSelect: (view: CalendarView) => any;
  projects: Project[];
}

const props = withDefaults(defineProps<CalendarHeaderProps>(), {
  calendarView: CalendarView.Month,
  startDayOfWeek: StartDayOfWeeks.Monday,
});

// get the earliest month and the oldest month from dates props
const monthArray = computed(() => {
  const earliestMonth = props.dates[0].date;
  const oldestMonth = props.dates[props.dates.length - 1].date;
  return [earliestMonth, oldestMonth];
});

const isSameMonth = computed(() => {
  return monthArray.value[0].isSame(monthArray.value[1], "month");
});

const calendarViews = computed(() => Object.values(CalendarView));
const persistedCalendarStore = usePersistedCalendarStore();
const { globalStore } = useGlobalState();
const { projectStore } = useProjectState();
const selectedCustomDate = ref<Dayjs>(props.displayedDate);
const showCustomPicker = ref(false);

const newEventOnClick = () => {
  projectStore.clearSelectedProjectId();
  globalStore.setIsModalFormEventShown(true);
};

const onDateClicked = (date: any) => {
  props.onCustomDate(dayjsUtil(date));
};

watchEffect(() => {
  if (props.displayedDate) {
    selectedCustomDate.value = props.displayedDate;
  }
});
</script>
