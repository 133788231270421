import { Event } from "@/interfaces/events";
import { Project } from "@/interfaces/projects";
import { defineStore } from "pinia";

interface ProjectStore {
  selectedEvent?: Event;
  selectedProjectId?: number;
}

export const useProjectStore = defineStore("projects", {
  state: (): ProjectStore => ({
    selectedEvent: undefined,
    selectedProjectId: undefined,
  }),
  actions: {
    setSelectedEvent(event: Event) {
      this.selectedEvent = event;
    },
    setSelectedProjectId(projectId: number) {
      this.selectedProjectId = projectId;
    },
    clearSelectedProjectId() {
      this.selectedProjectId = undefined;
    },
  },
});
