import { useCalendarStore } from "@/stores/calendars";
import { storeToRefs } from "pinia";

export const useCalendarState = () => {
  const calendarStore = useCalendarStore();
  const refs = storeToRefs(calendarStore);
  return {
    calendarStore,
    ...refs,
  };
};
