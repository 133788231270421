<template>
  <Combobox as="div" multiple>
    <div class="relative">
      <ComboboxInput
        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        @change="query = $event.target.value"
        :display-value="() => displayValue"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <div
          v-if="selectedProjects.length > 0"
          class="py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-200"
          @click="
            selectedProjects.length > 0 && onClearAll ? onClearAll() : undefined
          "
        >
          Clear all selections
        </div>
        <div
          v-if="selectedProjects.length != projects.length"
          class="py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-200"
          @click="persistedCalendarStore.setSelectedProjects(projects)"
        >
          Select all projects
        </div>
        <div class="py-2 pl-3 pr-9" v-if="projects.length === 0">
          No projects to be selected
        </div>
        <ComboboxOption
          v-for="project in filteredProjects"
          :key="project.id"
          :value="project"
          v-slot="{ active }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-main-600 text-white' : 'text-gray-900',
            ]"
            @click="toggleSelected(project)"
          >
            <div class="flex items-center">
              <div
                class="bg-gray-200 w-5 h-5 flex items-center justify-center rounded-full"
                :style="{
                  backgroundColor: project.color,
                }"
              >
                <span
                  :style="{
                    color: project.is_dark ? 'white' : 'black',
                  }"
                  class="text-md font-medium text-gray-800 leading-8"
                  >{{ project.name.at(0)?.toUpperCase() }}</span
                >
              </div>
              <span
                :class="[
                  'ml-3 truncate',
                  isInCheckedState(project) && 'font-semibold',
                ]"
              >
                {{ project.name }}
              </span>
            </div>

            <span
              v-if="isInCheckedState(project)"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { useProjectState } from "@/hooks/use_projects";
import { Project } from "@/interfaces/projects";
import { usePersistedCalendarStore } from "@/stores/calendars";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";

interface MoleculeProjectSelectorProps {
  onSelectedChange?: (projects: Project[]) => void;
  onClearAll?: () => void;
}

enum SelectedView {
  ALL_PROJECTS = "All projects",
  NO_PROJECTS_TO_BE_SELECTED = "No projects to be selected",
  MULTIPLE_PROJECTS = "Multiple projects",
  NO_PROJECT_SELECTED = "No project selected",
}

const props = defineProps<MoleculeProjectSelectorProps>();
const { projects } = useProjectState();
const displayValue = computed(() => {
  if (projects.length === 0) {
    return SelectedView.NO_PROJECTS_TO_BE_SELECTED;
  }
  if (selectedProjects.value.length === 0) {
    return SelectedView.NO_PROJECT_SELECTED;
  }
  if (selectedProjects.value.length === 1) {
    return selectedProjects.value[0].name;
  }
  if (isAllProjectChecked.value) {
    return SelectedView.ALL_PROJECTS;
  }
  return SelectedView.MULTIPLE_PROJECTS;
});
const isAllProjectChecked = computed(() => {
  return selectedProjects.value.length === projects.length;
});
const persistedCalendarStore = usePersistedCalendarStore();
const { selectedProjects } = storeToRefs(persistedCalendarStore);
const query = ref<string>("");
const filteredProjects = computed(() => {
  if (query.value === "") {
    return projects;
  }
  return projects.filter((project) =>
    project.name.toLowerCase().includes(query.value.toLowerCase())
  );
});

const isInCheckedState = (project: Project) => {
  return selectedProjects.value.some((p) => p.id === project.id);
};

const toggleSelected = (project: Project) => {
  if (
    selectedProjects.value.length === 1 &&
    selectedProjects.value[0].id === project.id
  ) {
    selectedProjects.value = [project];
    return;
  }
  if (isInCheckedState(project)) {
    selectedProjects.value = selectedProjects.value.filter(
      (p) => p.id !== project.id
    );
  } else {
    selectedProjects.value = [...selectedProjects.value, project];
  }
  if (props.onSelectedChange) {
    props.onSelectedChange(selectedProjects.value);
  }
};

onMounted(() => {
  if (props.onSelectedChange) {
    props.onSelectedChange(selectedProjects.value);
  }
});
</script>
