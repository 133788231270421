import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePage } from "./pages";
import PortalValue from "portal-vue";
import { Head, Link } from "@inertiajs/vue3";
import api from "./api";
import { createPinia } from "pinia";
import FloatingVue from "floating-vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "floating-vue/dist/style.css";

const inertiaApp = () =>
  createInertiaApp({
    resolve: resolvePage,
    setup({ el, App, props, plugin }) {
      const pinia = createPinia();
      pinia.use(piniaPluginPersistedstate);

      const vueApp = createApp({ render: () => h(App, props) })
        .use(plugin)
        .use(pinia)
        .use(PortalValue)
        .use(FloatingVue)
        .component("InertiaHead", Head)
        .component("InertiaLink", Link)
        .component("VueDatePicker", VueDatePicker);

      vueApp.config.globalProperties.$api = api;
      if (el) vueApp.mount(el);

      return vueApp;
    },
  });

export default inertiaApp;
