export interface ColorContrastResult {
  isDark: boolean;
  isLight: boolean;
}

export const detectContrast = (color: string): ColorContrastResult => {
  const contrastValue = luma(color);
  return {
    isDark: contrastValue < 165,
    isLight: contrastValue >= 165,
  };
};

export const randomString = (length: number) => {
  return Math.random()
    .toString(36)
    .slice(2, length + 2);
};

// color can be a hx string or an array of RGB values 0-255
const luma = (color) => {
  var rgb = typeof color === "string" ? hexToRGBArray(color) : color;
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
};

const hexToRGBArray = (color: string) => {
  color = color.replaceAll("#", "");
  if (color.length === 3)
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2);
  else if (color.length !== 6) throw "Invalid hex color: " + color;
  var rgb: number[] = [];
  for (var i = 0; i <= 2; i++) {
    const parsed = parseInt(color.substring(i * 2, i * 2 + 2), 16);
    rgb[i] = parsed;
  }
  return rgb;
};

export const randomColor = (): string => {
  return `#${Math.random().toString(16).slice(-6)}`.toUpperCase();
};
