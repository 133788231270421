// import Layout from "@/layouts/LayoutMinimal.vue"
const pages = import.meta.env.SSR ? import.meta.globEagerDefault('./pages/**/*.vue') : import.meta.glob('./pages/**/*.vue')

export async function resolvePage (name: string) {
  let page = pages[`./pages/${name}.vue`]

  if (!page)
    throw new Error(`Unknown page ${name}. Is it located under /pages with a .vue extension?`)

  // page.default.layout = page.default.layout || Layout;
  return import.meta.env.SSR ? page : (await page()).default

}
