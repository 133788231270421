<template>
  <div class="flex flex-auto">
    <div
      class="sticky left-0 z-10 w-16 flex-none bg-gray-50 ring-1 ring-gray-100"
    />
    <div
      ref="weeklyGridRef"
      class="grid flex-auto grid-cols-1 grid-rows-1 relative"
    >
      <!-- Ticker -->
      <div
        ref="tickerRef"
        v-show="isTickerShown"
        class="absolute h-0.5 flex bg-indigo-500 z-[99] transition-all duration-300"
        :style="{ width: 'calc(100%/7)' }"
      >
        <span
          class="absolute text-[10px] ml-2 mt-[-15px] bg-white px-1 rounded-sm text-indigo-500 font-semibold"
          >{{ todayTime.format("HH:mm") }}</span
        >
        <span
          class="absolute w-4 h-4 -ml-3 -mt-[7px] z-10 bg-indigo-500 rounded-full"
        />
      </div>
      <!-- Horizontal lines -->
      <div
        class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
        :style="{
          gridTemplateRows: `repeat(24, minmax(${hourInPixel}px, 1fr))`,
        }"
      >
        <!-- <div class="row-end-1 h-7" /> -->
        <template v-for="date in twentyFourHours()" :key="date.format('HH')">
          <div>
            <div
              class="sticky left-0 z-10 -ml-14 -mt-2.5 w-14 pr-4 text-right text-xs leading-5 text-gray-400"
            >
              {{
                date.hour() == 0 ? "" : date.set("minute", 0).format("HH:mm")
              }}
            </div>
          </div>
        </template>
      </div>

      <!-- Vertical lines -->
      <div
        ref="gridColumnsRef"
        class="overflow-y-hidden col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7"
      >
        <div
          class="relative select-none"
          v-for="dateItem in dates"
          :key="`event-${dateItem.key}`"
        >
          <MoleculeWeeklyEventCard
            v-for="(event, indexEvent) in computedEvents[
              getEventKey(dateItem.date)
            ]"
            :key="`daily-event-${dateItem.key}-${indexEvent}`"
            :event="event"
            @click="eventCardOnSelect(event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateItem, StartDayOfWeeks } from "@/constants/calendar";
import { Event, CalendarCellEvent } from "@/interfaces/events";
import { ComputedEvent, EventCreator } from "@/modules/event_creator";
import { getParentRef } from "@/utils/component_utils";
import dayjsUtil from "@/utils/dayjs_util";
import { Dayjs } from "dayjs";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import MoleculeWeeklyEventCard from "./MoleculeWeeklyEventCard.vue";
import { SlideOverType, useCalendarStore } from "@/stores/calendars";

const getEventKey = (date: Dayjs) => date.format("YYYY-MM-DD");
const hourInPixel = 50;
const minutesInPixel = hourInPixel / 60;
const gridPaddingTop = 0;
const todayLocal = () => dayjsUtil().utc().local();
const calendarStore = useCalendarStore();
const computedEvents = ref<{ [key: string]: ComputedEvent[] }>({});

watch(
  () => props.groupedEvent,
  () => {
    if (!gridColumnsRef.value) return;
    const gridColumsElement = gridColumnsRef.value;
    const eventCreator = new EventCreator({
      groupedEvent: props.groupedEvent ?? {},
      gridElement: gridColumsElement,
      datesColumn: props.dates.map((dateItem) => dateItem.date),
      hourInPixel,
      additionalGap: gridPaddingTop,
    });
    computedEvents.value = eventCreator.computeEvents();
  }
);
const eventCardOnSelect = (event: Event) => {
  calendarStore.setSelectedEvent(event);
  calendarStore.setSlideOverType(SlideOverType.WEEKLY);
};

const calculateTopFromTime = (hours: number, minutes: number) => {
  return hours * hourInPixel + minutes * minutesInPixel + gridPaddingTop;
};

const tickerRef = ref<HTMLDivElement | null>(null);
const weeklyGridRef = ref<HTMLElement | null>(null);
const gridColumnsRef = ref<HTMLDivElement | null>(null);
const todayTime = ref(todayLocal());
const twentyFourHours = () => {
  const today = dayjsUtil().hour(0).set("minute", 0);
  return Array.from({ length: 24 }, (_, i) => {
    return today.add(i, "hour");
  });
};

const isTickerShown = computed(() => {
  return props.dates.find((dateItem) =>
    todayLocal().isSame(dateItem.date, "date")
  );
});

const changeTickerPosition = (ticker: HTMLDivElement) => {
  const tickerWidth = ticker.getBoundingClientRect().width;
  ticker.style.left = `${todayTime.value.day() * tickerWidth}px`;
  ticker.style.top = `${
    todayTime.value.hour() * hourInPixel +
    todayTime.value.minute() * (hourInPixel / 60) +
    gridPaddingTop
  }px`;
};

interface CalendarWeeklyProps {
  displayedDate?: Dayjs;
  startDayOfWeek?: StartDayOfWeeks;
  groupedEvent?: CalendarCellEvent;
  dates: DateItem[];
}

const props = withDefaults(defineProps<CalendarWeeklyProps>(), {
  displayedDate: () => dayjsUtil(),
  startDayOfWeek: StartDayOfWeeks.Monday,
});

let tickerInterval;
watchEffect(() => {
  if (!isTickerShown.value && tickerInterval) {
    clearInterval(tickerInterval);
    return;
  }
  tickerInterval = setInterval(() => {
    const newToday = todayLocal();
    todayTime.value = newToday;
    if (tickerRef.value) {
      const tickerWidth = tickerRef.value.getBoundingClientRect().width;
      tickerRef.value.style.left = `${todayTime.value.day() * tickerWidth}px`;
      tickerRef.value.style.top = `${calculateTopFromTime(
        newToday.hour(),
        newToday.minute()
      )}px`;
    }
  }, 1000);
});

onMounted(() => {
  const calendarScrollableRef = getParentRef(
    "calendarScrollableRef"
  ) as HTMLDivElement;
  if (calendarScrollableRef && tickerRef.value) {
    changeTickerPosition(tickerRef.value);
    // Auto scroll to the ticker
    const offset = window.innerHeight / 2;
    const tickerPosition = tickerRef.value.getBoundingClientRect().top;
    const offsetPosition = tickerPosition + window.scrollY - offset;
    calendarScrollableRef.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  window.addEventListener("resize", () => {
    if (!tickerRef.value) return;

    changeTickerPosition(tickerRef.value);
  });
});
</script>
