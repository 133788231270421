import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone); // Extend Day.js with the timezone plugin

const dayjsUtil = dayjs;
export default dayjsUtil;

export const DISPLAY_FORMAT = "DD MMM YYYY, HH:mm";
