// JsFromRoutes CacheKey 8aa4e6023eed1e6646549989766998bd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  new: definePathHelper('get', '/login'),
  create: definePathHelper('post', '/login'),
  destroy: definePathHelper('delete', '/logout'),
  newResendConfirmation: definePathHelper('get', '/resend-confirmation'),
  resendConfirmation: definePathHelper('post', '/resend-confirmation'),
}
