// JsFromRoutes CacheKey 15834276b9d6dde903e479d86b22bcba
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  new: definePathHelper('get', '/reset-password'),
  create: definePathHelper('post', '/reset-password'),
  edit: definePathHelper('get', '/edit-password'),
  update: definePathHelper('put', '/edit-password'),
}
