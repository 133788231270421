import { ProjectEventMetadata } from "@/interfaces/events";
import { Dayjs } from "dayjs";

export enum StartDayOfWeeks {
  Sunday = 0,
  Monday = 1,
}

export enum CalendarView {
  Month = "Month",
  Week = "Week",
  Day = "Day",
}

export interface DateItem {
  date: Dayjs;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  key: string;
  projectMetadata: ProjectEventMetadata[];
}
