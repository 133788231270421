import { DateItem, StartDayOfWeeks } from "@/constants/calendar";
import dayjsUtil from "@/utils/dayjs_util";
import { Dayjs } from "dayjs";

export const datesInWeek = (
  displayedDate: Dayjs,
  startDayOfWeek: StartDayOfWeeks
): DateItem[] => {
  const today = dayjsUtil();
  let dayFirstWeek = displayedDate.add(-displayedDate.day());
  if (startDayOfWeek == StartDayOfWeeks.Monday) {
    dayFirstWeek = dayFirstWeek.add(-1, "day");
  }
  const arrOfWeek =
    startDayOfWeek == StartDayOfWeeks.Monday
      ? [1, 2, 3, 4, 5, 6, 7]
      : [0, 1, 2, 3, 4, 5, 6];
  return arrOfWeek.map((index) => {
    const date = dayFirstWeek.day(index);
    return {
      date,
      key: `week-${index}`,
      isToday: today.isSame(date, "date"),
      isSaturday: index == 6,
      isSunday: index == 0 || index == 7,
      projectMetadata: [],
    };
  });
};

export const datesInMonth = (
  displayedDate: Dayjs,
  startDayOfWeek: StartDayOfWeeks
) => {
  const today = dayjsUtil();
  const startOfMonth = displayedDate.startOf("month");
  const endOfMonth = displayedDate.endOf("month");
  let startOfMonthWeekNumber = startOfMonth.day() - startDayOfWeek;
  let endOfMonthWeekNumber = endOfMonth.day() - startDayOfWeek;
  if (startDayOfWeek == StartDayOfWeeks.Monday && startOfMonth.day() == 0) {
    startOfMonthWeekNumber = 6;
  }
  if (startDayOfWeek == StartDayOfWeeks.Monday && endOfMonth.day() == 0) {
    endOfMonthWeekNumber = 6;
  }
  const daysInPreviousMonth: DateItem[] = Array.from(
    { length: startOfMonthWeekNumber },
    (_, i) => {
      const index = (i + 1) * -1;
      const date = startOfMonth.add(index, "day");
      return {
        date,
        key: `prev-${date.date()}-${date.year()}`,
        isSaturday: date.day() == 6,
        isSunday: date.day() == 0,
        projectMetadata: [],
      };
    }
  ).reverse();
  const daysInNextMonth: DateItem[] = Array.from(
    { length: 6 - endOfMonthWeekNumber },
    (_, i) => {
      const index = i + 1;
      const date = endOfMonth.add(index, "day");
      return {
        date,
        key: `next-${date.date()}-${date.year()}`,
        isSaturday: date.day() == 6,
        isSunday: date.day() == 0,
        projectMetadata: [],
      };
    }
  );
  const daysInMonth: DateItem[] = Array.from(
    { length: endOfMonth.date() },
    (_, i) => {
      const key = i + 1;
      const date = displayedDate.date(key);
      return {
        date,
        isCurrentMonth: true,
        isToday: date.isSame(today, "date"),
        isSaturday: date.day() == 6,
        isSunday: date.day() == 0,
        key: `curr-${date.date()}-${date.year()}`,
        projectMetadata: [],
      };
    }
  );
  return daysInPreviousMonth.concat(daysInMonth).concat(daysInNextMonth);
};

export const rowCountInMonth = (datesLength: number) => {
  if (datesLength == 0) return 0;
  return Math.ceil(datesLength / 7);
};
