<template>
  <v-tooltip
    placement="top"
    :class="['absolute', event.forceTopLayerOnHover ? 'hover:!z-[99]' : '']"
    :style="{
      transform: `translateY(${event.topOffset}px)`,
      width: `${event.widthPercent}%`,
      left: `${event.leftPercent}%`,
      height: `${event.heightPixel}px`,
    }"
    class="absolute"
  >
    <div
      class="text-left rounded-md group border border-white border-opacity-50 hover:min-h-[50px] transition-shadow p-1 text-xs font-semibold hover:shadow-lg cursor-pointer"
      :class="['w-full h-full']"
      :style="{
        backgroundColor: eventBgColor,
        color: eventFgColor,
      }"
    >
      <div class="truncate pointer-events-none">{{ event.name }}</div>
      <div
        v-if="event.isMultipleDays"
        class="opacity-50 text-[10px] pointer-events-none"
      >
        {{ startDate.format("DD MMM") }} -
        {{ endDate.format("DD MMM HH:mm") }}
        {{ humanizedDuration.length > 0 ? `(${humanizedDuration})` : "" }}
      </div>
      <div
        v-else
        class="opacity-50 text-[10px] pointer-events-none"
        aria-duration="true"
      >
        {{ startDate.format("HH:mm") }} -
        {{ endDate.format("HH:mm") }}
        {{ humanizedDuration.length > 0 ? `(${humanizedDuration})` : "" }}
      </div>
    </div>
    <template #popper>
      {{ event.project?.name }}
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { ComputedEvent } from "@/modules/event_creator";
import { detectContrast } from "@/utils/common_utils";
import dayjsUtil from "@/utils/dayjs_util";
import { humanizeDayjsDuration } from "@/utils/event_utils";
import { computed } from "vue";
interface AtomWeeklyEventCalendarProps {
  event: ComputedEvent;
}
const props = defineProps<AtomWeeklyEventCalendarProps>();
const startDate = dayjsUtil(props.event.start_date);
const endDate = dayjsUtil(props.event.end_date);
const humanizedDuration = computed(() => {
  return humanizeDayjsDuration(
    dayjsUtil.duration(endDate.diff(startDate)),
    true
  );
});
const eventBgColor = props.event.project?.color || "#d8e7cb";
const eventFgColor = computed(() =>
  detectContrast(eventBgColor).isDark ? "white" : "black"
);
</script>
