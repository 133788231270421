import {
  ArchiveBoxIcon,
  ClockIcon,
  DocumentCheckIcon,
  PauseCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import plugin from "dayjs/plugin/duration";
import pluralize from "pluralize";
import { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
export const humanizeDayjsDuration = (
  duration: plugin.Duration,
  short?: boolean
) => {
  const extractedDuration = extractDuration(duration, short);
  const durationKeys = Object.keys(extractedDuration);
  return durationKeys
    .map((key): ExtractedDurationItem => extractedDuration[key])
    .filter((v) => v.value != 0)
    .map((v) => (short ? `${v.value}${v.unit}` : `${v.value} ${v.unit}`))
    .join(",");
};

interface ExtractedDurationItem {
  value: number;
  unit: string;
}

interface ExtractedDuration {
  years: ExtractedDurationItem;
  months: ExtractedDurationItem;
  days: ExtractedDurationItem;
  hours: ExtractedDurationItem;
  minutes: ExtractedDurationItem;
  seconds: ExtractedDurationItem;
}

export const extractDuration = (
  duration: plugin.Duration,
  short?: boolean
): ExtractedDuration => {
  return {
    years: {
      value: duration.years(),
      unit: short ? "y" : pluralize("year", duration.years()),
    },
    months: {
      value: duration.months(),
      unit: short ? "mo" : pluralize("month", duration.months()),
    },
    days: {
      value: duration.days(),
      unit: short ? "d" : pluralize("day", duration.days()),
    },
    hours: {
      value: duration.hours(),
      unit: short ? "h" : pluralize("hour", duration.hours()),
    },
    minutes: {
      value: duration.minutes(),
      unit: short ? "m" : pluralize("minute", duration.minutes()),
    },
    seconds: {
      value: duration.seconds(),
      unit: short ? "s" : pluralize("second", duration.seconds()),
    },
  };
};

export interface EventStatus {
  text: string;
  value: string;
  icon: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
}

export const eventStatusMap: { [key: string]: EventStatus } = {
  kiv: {
    text: "KIV",
    value: "kiv",
    icon: ArchiveBoxIcon,
  },
  postponed: {
    text: "Postponed",
    value: "postponed",
    icon: PauseCircleIcon,
  },
  cancelled: {
    text: "Cancelled",
    value: "cancelled",
    icon: XMarkIcon,
  },
  backup: {
    text: "Backup",
    value: "backup",
    icon: ClockIcon,
  },
  completed: {
    text: "Completed",
    value: "completed",
    icon: DocumentCheckIcon,
  },
};

export const filterOptions = Object.values(eventStatusMap);
