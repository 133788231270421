// JsFromRoutes CacheKey 27bc7bf2c25f3af66e18a57e74b51ae7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  new: definePathHelper('get', '/users/confirmation/new'),
  get: definePathHelper('get', '/users/confirmation'),
  create: definePathHelper('post', '/users/confirmation'),
}
