// JsFromRoutes CacheKey f087cc6cf47027a3e0dbb798c0b84097
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  list: definePathHelper('get', '/'),
  createEvent: definePathHelper('post', '/projects/:id/create_event'),
  updateEvent: definePathHelper('put', '/projects/:id/update_event/:event_id'),
  updateStatus: definePathHelper('put', '/projects/:id/update_status/:event_id'),
  destroyEvent: definePathHelper('delete', '/projects/:id/destroy_event/:event_id'),
  create: definePathHelper('post', '/projects'),
  new: definePathHelper('get', '/projects/new'),
  edit: definePathHelper('get', '/projects/:id/edit'),
  get: definePathHelper('get', '/projects/:id'),
  update: definePathHelper('patch', '/projects/:id'),
  destroy: definePathHelper('delete', '/projects/:id'),
}
