// JsFromRoutes CacheKey aaca5f650e7b666a427ec05c3d867117
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  setting: definePathHelper('get', '/profile/setting'),
  update: definePathHelper('put', '/profile/setting'),
  updateEmail: definePathHelper('put', '/profile/update-email'),
  resendConfirmation: definePathHelper('post', '/profile/resend-confirmation'),
}
