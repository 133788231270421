// JsFromRoutes CacheKey 071459aa45d83000baee3bc369f2ae53
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/inertia'

export default {
  new: definePathHelper('get', '/register'),
  create: definePathHelper('post', '/register'),
  update: definePathHelper('put', '/update-password'),
}
