import { ComponentInternalInstance, getCurrentInstance, useSlots } from "vue";

export const getParentRef = (
  refName: string,
  currentInstance?: ComponentInternalInstance
) => {
  const instance = currentInstance ?? getCurrentInstance();
  const foundRef = instance?.refs[refName];
  if (foundRef) return foundRef;
  if (instance?.parent != null) return getParentRef(refName, instance.parent);
  return null;
};

export const hasSlot = (name: string) => !!useSlots()[name];
