import { Event } from "@/interfaces/events";
import { randomString } from "@/utils/common_utils";
import dayjsUtil from "@/utils/dayjs_util";
import { Dayjs } from "dayjs";

export interface ComputedEvent extends EventModelCreator {
  collisionGroupName: string;
  // used to determine width for each collisioned items
  collisionCount: number;
  // used to determine left percentage based on the collission
  collisionIndex: number;
  widthPercent: number;
  heightPixel: number;
  topOffset: number;
  leftPercent: number;
  forceTopLayerOnHover: boolean;
  isMultipleDays: boolean;
}

interface EventCreatorConstructor {
  gridElement: HTMLDivElement;
  datesColumn: Dayjs[];
  groupedEvent: { [key: string]: Event[] };
  hourInPixel?: number;
  additionalGap?: number;
  maximumOverlapRender?: number;
}

interface EventModelCreator extends Event {
  dom?: HTMLElement;
}
export class EventCreator {
  private hourInPixel: number;
  maximumOverlapRender: number;
  eventModelsMapped: { [key: string]: ComputedEvent[] };
  datesColumn: Dayjs[];
  additionalGap: number;
  gridColumnIdentifier: string;
  eventCardIdentifier: string;
  scrollableParent: HTMLElement;
  gridElement: HTMLElement;

  constructor(props: EventCreatorConstructor) {
    this.hourInPixel = props.hourInPixel ?? 50;
    this.additionalGap = props.additionalGap ?? 28;
    this.gridElement = props.gridElement;
    this.datesColumn = props.datesColumn;
    this.maximumOverlapRender = props.maximumOverlapRender || 4;
    const mappedGroupedEvent: { [key: string]: ComputedEvent[] } = {};
    for (const key in props.groupedEvent) {
      const eventModels = props.groupedEvent[key];
      const computedEvents = eventModels.map((eventModel) => {
        const { start_date, end_date } = eventModel;
        const minutes = dayjsUtil(end_date).diff(start_date, "minutes");
        const topOffset = this.getTopOffsetFromStartTime(dayjsUtil(start_date));
        return {
          ...eventModel,
          collisionGroupName: "",
          collisionCount: 0,
          collisionIndex: 0,
          widthPercent: 100,
          heightPixel: minutes * this.minutesInPixel,
          topOffset: topOffset,
          leftPercent: 0,
          forceTopLayerOnHover: false,
          isMultipleDays: dayjsUtil(end_date).diff(start_date, "day") > 0,
        };
      });
      mappedGroupedEvent[key] = computedEvents;
    }
    this.eventModelsMapped = mappedGroupedEvent;
    this.gridColumnIdentifier = randomString(5);
    this.eventCardIdentifier = randomString(5);
    this.scrollableParent = this.getScrollableParent(props.gridElement);
  }

  computeEvents(): { [key: string]: ComputedEvent[] } {
    this.sortEventModelsMapped();
    return this.eventModelsMapped;
  }

  private sortEventModelsMapped() {
    const eventMappedTmp: { [dateStr: string]: ComputedEvent[] } = {};
    const eventModelsMapped = this.eventModelsMapped;
    for (const key in eventModelsMapped) {
      const dateKey = dayjsUtil(key);
      const eventModels = eventModelsMapped[key].sort(
        (a: ComputedEvent, b: ComputedEvent) =>
          dayjsUtil(a.start_date).isBefore(b.start_date) ? -1 : 1
      );
      const overlapedItems: { [groupName: string]: number } = {};
      let randomGroupKey: string | null = randomString(5);

      let lastComputedEvent: ComputedEvent | null = null;
      // Index will be used for OffsetLeft later
      let collisionIndex = 0;
      // Assign the group name for each collisioned events
      // AND save the group metadata to ``overlapedItems`` variable
      let eventWithGroupName = eventModels.reduce(
        (res: ComputedEvent[], curr, idx) => {
          const computedObj = {
            // Need to calculate later in above steps
            collisionCount: 0,
            collisionIndex: 0,
            collisionGroupName: "",
            widthPercent: 0,
            heightPixel: 0,
            topOffset: 0,
            leftPercent: 0,
            forceTopLayerOnHover: false,
          };
          // compare to last computed event to check the collision
          // then assign a group name for each collision
          if (lastComputedEvent) {
            let currentStartDate = dayjsUtil(curr.start_date);
            let lastStartDate = dayjsUtil(lastComputedEvent.start_date);
            // set start_date to the beginning of the day if it's from previous day
            if (dateKey.isAfter(curr.start_date, "date")) {
              currentStartDate = dateKey.startOf("day");
            }
            if (dateKey.isAfter(lastComputedEvent.start_date, "date")) {
              lastStartDate = dateKey.startOf("day");
            }
            const overlapInMinutes = dayjsUtil(currentStartDate).diff(
              lastStartDate,
              "minutes"
            );
            if (overlapInMinutes < 30) {
              // if previous step is not collision (it will null) and we need to regenerate a new one
              if (!randomGroupKey) randomGroupKey = randomString(5);
              computedObj.collisionGroupName = randomGroupKey;
              overlapedItems[randomGroupKey] =
                (overlapedItems[randomGroupKey] ?? 0) + 1;
              if (!lastComputedEvent.collisionGroupName) {
                lastComputedEvent.collisionGroupName = randomGroupKey;
                // Increase another one to include previous UNcollisioned event
                overlapedItems[randomGroupKey] =
                  overlapedItems[randomGroupKey] + 1;
                lastComputedEvent.collisionIndex = collisionIndex;
              }
              collisionIndex += 1;
              computedObj.collisionIndex = collisionIndex;

              const currentOverlapCount = overlapedItems[randomGroupKey];
              if (
                currentOverlapCount &&
                currentOverlapCount >= this.maximumOverlapRender
              ) {
                randomGroupKey = randomString(5);
                collisionIndex = -1;
              }
            } else {
              randomGroupKey = null;
              collisionIndex = 0;
            }
          }

          lastComputedEvent = {
            ...curr,
            ...computedObj,
          };
          res.push(lastComputedEvent);
          return res;
        },
        []
      );
      const computedEvents = eventWithGroupName.map((computedEvent) => {
        const { collisionGroupName, end_date, start_date, collisionIndex } =
          computedEvent;
        let widthPercent = 100;
        let collisionCount = 0;
        // Check if the groupname exists then lets assign total collision count
        if (
          collisionGroupName &&
          Object.prototype.hasOwnProperty.call(
            overlapedItems,
            collisionGroupName
          )
        ) {
          collisionCount = overlapedItems[collisionGroupName];
          computedEvent.collisionCount = collisionCount;
          widthPercent = 100 / collisionCount;
        }
        const startDateMultipleDay = computedEvent.isMultipleDays
          ? dateKey
          : start_date;
        const minutes = dayjsUtil(end_date).diff(
          startDateMultipleDay,
          "minutes"
        );
        const heightInPixel = minutes * this.minutesInPixel;
        computedEvent.widthPercent = widthPercent;
        computedEvent.heightPixel = heightInPixel > 24 ? heightInPixel : 24;
        computedEvent.topOffset = this.getTopOffsetFromStartTime(
          dayjsUtil(start_date)
        );
        // make the topOffset to 0 if the event is from previous day
        if (dateKey.isAfter(computedEvent.start_date, "date")) {
          computedEvent.topOffset = this.additionalGap;
        }
        computedEvent.leftPercent = widthPercent * collisionIndex;
        computedEvent.forceTopLayerOnHover = collisionCount > 0;
        return computedEvent;
      });
      eventMappedTmp[key] = computedEvents;
    }
    this.eventModelsMapped = eventMappedTmp;
  }

  private get minutesInPixel(): number {
    return this.hourInPixel / 60;
  }

  private getTopOffsetFromStartTime(startTime: Dayjs): number {
    const minutes = startTime.hour() * 60 + startTime.minute();
    return minutes * this.minutesInPixel + this.additionalGap;
  }

  private isScrollable(ele: HTMLElement): boolean {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1;

    return hasScrollableContent && !isOverflowHidden;
  }

  private getScrollableParent(elem): HTMLElement {
    return !elem || elem === document.body
      ? document.body
      : this.isScrollable(elem)
      ? elem
      : this.getScrollableParent(elem.parentNode);
  }
}
