import { Event, ProjectEventMetadata } from "@/interfaces/events";
import { Project } from "@/interfaces/projects";
import dayjs, { Dayjs } from "dayjs";
import { defineStore } from "pinia";

interface PersistedCalendarStore {
  selectedProjects: Project[];
  selectedYear: number;
}
export enum SlideOverType {
  NONE = "none",
  MONTHLY = "monthly",
  WEEKLY = "weekly",
  DAILY = "daily",
}

interface CalendarStore {
  selectedEvent?: Event;
  slideOverType: SlideOverType;
  groupedEventInSelectedDays: ProjectEventMetadata[];
  selectedDate?: Dayjs;
}

export const usePersistedCalendarStore = defineStore("persisted-calendars", {
  state: (): PersistedCalendarStore => ({
    selectedProjects: [],
    selectedYear: dayjs().year(),
  }),
  actions: {
    setSelectedProjects(projects: Project[]) {
      this.selectedProjects = projects;
    },
    setSelectedYear(year: number) {
      this.selectedYear = year;
    },
  },
  persist: true,
});

export const useCalendarStore = defineStore("calendars", {
  state: (): CalendarStore => ({
    selectedEvent: undefined,
    slideOverType: SlideOverType.NONE,
    groupedEventInSelectedDays: [],
    selectedDate: undefined,
  }),
  actions: {
    setSelectedEvent(event: Event) {
      this.selectedEvent = event;
    },
    clearSelectedEvent() {
      this.selectedEvent = null;
    },
    setSlideOverType(val: SlideOverType) {
      this.slideOverType = val;
    },
    hideSlideOver() {
      this.slideOverType = SlideOverType.NONE;
    },
    setGroupedEventInSelectedDays(events: ProjectEventMetadata[]) {
      this.groupedEventInSelectedDays = events;
    },
    setSelectedDate(date: Dayjs) {
      this.selectedDate = date;
    },
  },
});
